/* autoprefixer grid: autoplace */
import { graphql } from "gatsby";
import Cookies from "universal-cookie";
import React from "react";

import { REFERRAL_COOKIE, AFTERWORD_COOKIE_PARAMS } from "../constants/cookies";
import { Routes } from "../constants/routes";
import Layout from "../components/layout-main";

import Img from "gatsby-image";

/** @jsx jsx */
import { jsx, Grid, Flex, Button, Styled } from "theme-ui";
const VirtualFuneralsPage = ({ data, location }) => {
  const refLink = String(location.pathname)
    .split(Routes.ARBOR_DIRECTORS)[1]
    .replace("/", "");

  const cookies = new Cookies();
  // TODO: set secure only for production environments
  if (refLink) {
    cookies.set(REFERRAL_COOKIE, refLink, AFTERWORD_COOKIE_PARAMS);
  }

  return (
    <Layout
      location={location}
      pageTitle="Arbor | Elevated Livestreaming"
      hideSubscribe={true}
      hideLinks={true}
      hideNonCompanyLinks={true}
      staticHeaderLink={true}
      noIndex={true}
    >
      <section className="aw-accent">
        <div className="aw-content" sx={{ textAlign: "center" }}>
          <Grid
            gap={[3]}
            columns={["1fr 3fr 1fr"]}
            sx={{
              alignItems: "center",
              alignContent: "center",
              maxWidth: "1000px",
              // for IE 11
              display: "-ms-grid",
              "-ms-grid-columns": "1fr 3fr 1fr",
            }}
          >
            <div sx={{ width: "48px", margin: "0 auto" }}>
              <Img
                title="Arbor Memorials Logo"
                alt="Arbor Memorials Logo"
                sx={{
                  width: ["60%", "90%"],
                  margin: ["0 auto"],
                }}
                fluid={data.arbor.childImageSharp.fluid}
              />
            </div>
            <div>
              <Styled.h1
                sx={{
                  fontWeight: "500",
                  color: "black",
                }}
              >
                Arbor & Afterword
              </Styled.h1>
            </div>

            <div sx={{ width: "48px", margin: "0 auto" }}>
              <Img
                title="Afterword Logo"
                alt="Afterword Logo"
                sx={{
                  width: ["60%", "90%"],
                  margin: ["0 auto", "0"],
                }}
                fluid={data.afterword.childImageSharp.fluid}
              />
            </div>
          </Grid>
        </div>
      </section>

      <section className="aw-bg-blue">
        <div className="aw-content">
          <Grid
            gap={[2, 2, 3]}
            columns={["auto", "1fr 1fr", "3fr 2fr", "4fr 3fr"]}
            sx={{
              display: "-ms-grid",
              "-ms-grid-columns": ["auto", "1fr 1fr", "3fr 2fr", "4fr 3fr"],
            }}
          >
            <Flex
              sx={{
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <div>
                <Styled.h2
                  sx={{
                    fontWeight: "400",
                    color: "white",
                  }}
                >
                  Livestreaming Services
                </Styled.h2>
                <p>
                    As of January 1st, 2024, we no longer offer Elevated Livestreaming services. We're honored to have supported Arbor families over the last few years.
                </p>
                <p>
                Our team is committed to ensuring a smooth transition and is available to assist with any questions or concerns. You can email any questions to arbor@afterword.com.
                </p>
              </div>
            </Flex>

            <Flex
              sx={{
                flexDirection: "column",
                justifyContent: "space-around",
                margin: ["0 5px", 0],
              }}
            >
              <Img
                title="livestream memorial collage"
                alt="livestream memorial collage"
                sx={{
                  width: ["60%", "100%"],
                  margin: ["0 auto", "0"],
                }}
                fluid={data.screenImage.childImageSharp.fluid}
              />
            </Flex>
          </Grid>
        </div>
      </section>

    </Layout>
  );
};

export default VirtualFuneralsPage;

export const pageQuery = graphql`
  query {
    arbor: file(relativePath: { regex: "/arbor-logo.png/" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    afterword: file(relativePath: { regex: "/afterword-logo.png/" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    screenImage: file(relativePath: { regex: "/interactive-cameras.png/" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
